export const library = [
    {
      title: "Sunflower",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0xd3618B6F05f1376fC7828FEDD932985536e0883F",
    },
    {
      title: "Shoulda Coulda Woulda",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0xC7846c62B4B345F3B72b40b2d24ebF5A9BD511ea",
    },
    {
      title: "Know Better",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0x87b151C2E7652dd52da86516Cd8BDc87e7652f08",
    },
    {
      title: "Sunshine",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0x8f5588740fA1a6F46c83de3C054ecbe18934d08E",
    },
    {
      title: "Out of Sight",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0xE409011F25cF9eEe516f2922fc400698b9E46A29",
    },
    {
      title: "Paper Planes",
      image:
        "https://ipfs.moralis.io:2053/ipfs/Qma9pMhGTx4gMfWo5YuddVno76kqK16u94dSTaCPCDBHFD/media/1",
      contract: "0x4D6ded2a9c3ffDe3641B55FD0Ad698C651726Be7",
    },
    {
      title: "Hyperspace",
      image:
        "https://www.digitalartsonline.co.uk/cmsdata/slideshow/3776245/beck_-_hyperspace.jpg"
    },
    {
      title: "Non Fungible",
      image:
        "https://ipfs.moralis.io:2053/ipfs/QmTbF7gLPmtrZuNr7kZJ8iufxBVEPqrAdJsxujSmu9qbuh/art/NonFungible.png",
      contract: "0x1C45d483515A92fCDD0aa5eaF03dbdf1B7d6898a",
    },
    {
      title: "The Unknown",
      image:
        "https://350927.smushcdn.com/1388247/wp-content/uploads/2020/11/Unknown-Album-Cover-PP1.jpg?lossy=0&strip=1&webp=1",
    },
    {
      title: "Get Rich Or Die Tryin'",
      image: "https://m.media-amazon.com/images/I/61AYpcmBtpL._SY450_.jpg",
    },
    {
      title: "Starboy",
      image:
        "https://i.pinimg.com/originals/49/a0/7a/49a07a20041787942f62dbe900573ecb.jpg",
    },
    {
      title: "Evolve",
      image:
        "https://external-preview.redd.it/v_5XzwOWnjqrz5gBDfENNoMV9Kl8OAAy3015hcTsVYs.jpg?auto=webp&s=5485881fc8e107c14bca6d16c61fa085a78b70b6",
    },
    {
      title: "Ball Breaker",
      image:
        "https://scale.coolshop-cdn.com/product-media.coolshop-cdn.com/AN5W2E/b0cb0df8c30d479da36443c10bc85b96.jpg/f/ac-dc-ballbreaker-framed-album-cover-30x30cm.jpg",
    },
    {
      title: "Flavors",
      image:
        "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/125831034/original/c2e1426823f87d01f7b43085c6b20e61b0736794/create-you-a-special-artistic-album-cover.png",
    },
    {
      title: "Shakira",
      image:
        "https://upload.wikimedia.org/wikipedia/en/3/35/Shakira_-_Shakira_%282014%29.png",
    },
    {
      title: "Adele 21",
      image:
        "https://upload.wikimedia.org/wikipedia/fi/5/51/Adele21albumikansi.jpg",
    },
    {
      title: "Strange Clouds",
      image:
        "https://upload.wikimedia.org/wikipedia/en/6/69/B.o.B_-_Strange_Clouds_-_LP_Cover.jpg",
    },
    {
      title: "Shadow",
      image:
        "https://ipfs.moralis.io:2053/ipfs/QmX5NMV8hh1g5EcebX1e2Y55uQnVnKPk8YzW37wpnRWfXp/media/6",
      contract: "0x8A68d4e28515815CD6026416f4B2a4B647796F3E",
    },
    {
      title: "Arctic Monkeys",
      image:
        "https://i.pinimg.com/originals/c1/83/44/c1834474ec73e7faa475d68fdd791a48.jpg",
    },
    {
      title: "My Everything",
      image:
        "https://upload.wikimedia.org/wikipedia/en/d/d5/Ariana_Grande_My_Everything_2014_album_artwork.png",
    },
    {
      title: "Have A Nice Day",
      image:
        "https://upload.wikimedia.org/wikipedia/de/thumb/e/e5/Bon_Jovi_Have_a_Nice_Day.svg/1200px-Bon_Jovi_Have_a_Nice_Day.svg.png",
    },
    {
      title: "Happier",
      image:
        "https://upload.wikimedia.org/wikipedia/en/e/e5/Marshmello_and_Bastille_Happier.png",
    },
    {
      title: "Stoney",
      image:
        "https://upload.wikimedia.org/wikipedia/fi/d/d9/Post_Malone_Stoney.jpg",
    },
    {
      title: "The Eminem Show",
      image:
        "https://upload.wikimedia.org/wikipedia/en/3/35/The_Eminem_Show.jpg",
    },
    
  ];